import { Injectable, Type } from '@angular/core';
import { BooleanCellRendererComponent } from '@styles/p-table/cell-renderers/boolean-cell-renderer.component';
import { DateCellRendererComponent } from '@styles/p-table/cell-renderers/date-cell-renderer.component';
import { InfiniteDaysCellRendererComponent } from '@styles/p-table/cell-renderers/infinite-days-cell-renderer.component';
import { OverdueCellRendererComponent } from '@styles/p-table/cell-renderers/overdue-cell-renderer/overdue-cell-renderer.component';
import { CellRenderer } from '@styles/p-table/model/cell-renderer.model';
import { ValueGetterFunc } from '@styles/p-table/model/col-def.model';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as XLSX from 'xlsx';

import { INFINITE_DAYS} from '@shared/constants';
import { isEnum, isFunction, isString, isTrue, isUser, isUserArray, isNil, isNumber, unixToFormattedDate } from '@shared/utils';

import { UserSettingsColumnsDef } from '../../home/constants/column-definitions';

@Injectable({
  providedIn: 'root',
})
export class ExcelService<T = object> {
  private readonly excelExtension = '.xlsx';

  exportToExcel(data: T[], columns: UserSettingsColumnsDef<T>, fileName: string): void {
    const extractedData = this.getDataFromVisibleColumns(data, columns);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(extractedData);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}_export${this.excelExtension}`);
  }

  private getDataFromVisibleColumns(data: T[], columns: UserSettingsColumnsDef<T>): T[] {
    const fields = columns.map(column => ({
      field: column.field,
      header: column.header,
      dateColumn: this.isDateColumn(column.cellRenderer),
      infiniteCellColumn: this.isInfiniteCellColumn(column.cellRenderer),
      booleanColumn: this.isBooleanColumn(column.cellRenderer),
      exportValueGetter: column.exportValueGetter,
    }));

    return this.flatUserAndEnumItems(
      data.map(item => {
        const extractedItem: Record<string, unknown> = {};
        fields.forEach(({ field, dateColumn, infiniteCellColumn, booleanColumn, header, exportValueGetter }) => {
          if (item.hasOwnProperty(field)) {
            extractedItem[header] = this.getDataFromItem(item, field, dateColumn, infiniteCellColumn, booleanColumn, exportValueGetter) as T[Extract<
              keyof T,
              string
            >];
          }
        });
        return extractedItem as T;
      })
    );
  }

  private getDataFromItem(
    item: T,
    field: keyof T,
    dateColumn: boolean,
    infiniteCellColumn: boolean,
    booleanColumn: boolean,
    exportValueGetter: string | ValueGetterFunc<T>
  ): T[keyof T] | string | Date {
    const value: T[keyof T] = item[field];
    if (isFunction(exportValueGetter)) {
      return exportValueGetter(item) as string;
    } else if (isString(exportValueGetter)) {
      return exportValueGetter;
    }

    if (isTrue(booleanColumn)) {
      return isTrue(value) ? 'Yes' : 'No';
    }

    if ((infiniteCellColumn && value === INFINITE_DAYS) || isNil(value)) {
      return '';
    }

    if (!isTrue(dateColumn)) {
      return value;
    }

    return this.formatDateToExcelDate(value as string | number);
  }

  private formatDateToExcelDate(value: string | number): Date {
    let dateString = isNumber(value) ? unixToFormattedDate(value) : value;
    return new Date(new Date(dateString).toDateString());
  }

  private isInfiniteCellColumn(columnCellRenderer: Type<CellRenderer<T>>): boolean {
    return columnCellRenderer === InfiniteDaysCellRendererComponent;
  }

  private isDateColumn(columnCellRenderer: Type<CellRenderer<T>>): boolean {
    return columnCellRenderer === DateCellRendererComponent || columnCellRenderer === OverdueCellRendererComponent;
  }

  private isBooleanColumn(columnCellRenderer: Type<CellRenderer<T>>): boolean {
    return columnCellRenderer === BooleanCellRendererComponent;
  }

  private flatUserAndEnumItems(rows: T[]): T[] {
    return rows.map(row => this.flattenObject(row));
  }

  private flattenObject(obj: T): T {
    let result: Record<string, unknown> = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (isUser(value)) {
        result[key] = value.user_name;
        return;
      }

      if (isUserArray(value)) {
        result[key] = value.map(user => user.user_name).join(', ');
        return;
      }

      if (isEnum(value)) {
        result[key] = value.name;
        return;
      }
      result[key] = value;
    });

    return result as T;
  }
}
