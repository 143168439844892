import { isValid, parseISO } from 'date-fns';

export type DateInput = string | number | Date;

export const isValidDate = (date: DateInput): boolean => {
  if (typeof date === 'string') {
    try {
      const parsedDate = parseISO(new Date(date).toISOString());
      return isValid(parsedDate);
    } catch (e) {
      return false;
    }
  } else if (typeof date === 'number') {
    const parsedDate = new Date(date);
    return isValid(parsedDate);
  } else if (date instanceof Date) {
    return isValid(date);
  } else {
    return false;
  }
};
