import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseEnumItem } from 'oneorm-api-http-client';

import { secondLineIdentifiedLodId, selfIdentifiedLodId, thirdLineIdentifiedLodId } from '@shared/constants';

import {
  IssuesCheckboxFilter,
  IssuesIdentifyingLodCheckboxFilter,
  IssuesIdentifyingLodCheckboxFilterValue,
} from '../../home/model/issues-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

export abstract class LodEnumAdapter extends Adapter<
  Record<IssuesCheckboxFilter.identifyingLod, FilterCheckboxGroupModel>,
  RiskCaseEnumItem[]
> {
  static fromDto(dto: RiskCaseEnumItem[]): Record<IssuesCheckboxFilter.identifyingLod, FilterCheckboxGroupModel> {
    return {
      identifyingLod: new FilterCheckboxGroupModel<string, IssuesIdentifyingLodCheckboxFilter, IssuesIdentifyingLodCheckboxFilterValue>(
        'Identifying line of defense',
        [
          {
            filterName: IssuesIdentifyingLodCheckboxFilter.self,
            label: IssuesIdentifyingLodCheckboxFilterValue.self,
            value: LodEnumAdapter.getIdFromEnumItem(dto, selfIdentifiedLodId),
          },
          {
            filterName: IssuesIdentifyingLodCheckboxFilter.secondLine,
            label: IssuesIdentifyingLodCheckboxFilterValue.secondLine,
            value: LodEnumAdapter.getIdFromEnumItem(dto, secondLineIdentifiedLodId),
          },
          {
            filterName: IssuesIdentifyingLodCheckboxFilter.thirdLine,
            label: IssuesIdentifyingLodCheckboxFilterValue.thirdLineLine,
            value: LodEnumAdapter.getIdFromEnumItem(dto, thirdLineIdentifiedLodId),
          },
        ]
      ),
    };
  }

  static getIdFromEnumItem(dto: RiskCaseEnumItem[], id: number): string {
    return dto.find(enumItem => enumItem.id === id)?.id.toString();
  }
}
