import { NgForOf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatError, MatFormField, MatLabel, MatSuffix, SubscriptSizing } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TrimFormFieldsDirective } from '@shared/directives/trim-empty-space.directive';
import { debounceTime, distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { UsersService } from '@shared/services';
import { isNotNil, isString } from '@shared/utils';

import { FilterDropdownOption } from '../models/filter-dropdown-option.model';

@Component({
  selector: 'app-users-search-form-field',
  template: `
    <mat-form-field
      [class.full-width]="!halfWidth"
      [class.half-width]="halfWidth"
      [subscriptSizing]="subscriptSizing"
      appearance="fill"
      matTooltip="Type at least 3 characters to search"
      matTooltipPosition="after"
    >
      <mat-label>{{ label }}</mat-label>
      <input #input [formControl]="inputFormControl" [matAutocomplete]="autocomplete" [placeholder]="placeholder" matInput type="text" />
      <mat-autocomplete
        #autocomplete="matAutocomplete"
        [displayWith]="displayFn"
        (closed)="opened.set(false)"
        (opened)="opened.set(true)"
        (optionSelected)="optionSelected.emit($event.option.value)"
      >
        <mat-option>None</mat-option>
        @for (option of options(); track option.id) {
          <mat-option [id]="option.id" [value]="option">
            {{ option.label }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (inputFormControl.invalid && inputFormControl.touched) {
        <mat-error *ngFor="let key of getErrorKeys()">{{ getErrorMessage(key) }}</mat-error>
      }

      @if (spinnerVisible()) {
        <mat-spinner [diameter]="16" color="primary" matSuffix></mat-spinner>
      }
    </mat-form-field>
  `,
  standalone: true,
  imports: [
    MatFormField,
    MatTooltip,
    MatLabel,
    MatAutocompleteTrigger,
    MatInput,
    MatAutocomplete,
    MatOption,
    MatProgressSpinner,
    MatSuffix,
    ReactiveFormsModule,
    MatError,
    NgForOf,
    TrimFormFieldsDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSearchFormFieldComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() halfWidth?: boolean = false;
  @Input() inputFormControl? = new FormControl<string | FilterDropdownOption>(undefined);
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() getErrorMessage?: (errorKey: string) => string = this.defaultGetErrorMessage;
  @Output() optionSelected = new EventEmitter<FilterDropdownOption>();

  readonly options = signal<FilterDropdownOption[]>([]);
  readonly opened = signal(false);
  readonly loading = signal(false);
  readonly spinnerVisible = computed(() => this.opened() && this.loading());

  readonly listenToValueChanges = rxMethod<string | FilterDropdownOption>(
    pipe(
      debounceTime(500),
      filter(isNotNil),
      filter(isString),
      filter(query => query.length >= this.minimumRequiredCharacters && this.opened()),
      distinctUntilChanged(),
      tap(() => this.loading.set(true)),
      switchMap(query =>
        this.usersService.getUsers(query).pipe(
          tapResponse({
            next: data => {
              this.options.set(data);
              this.loading.set(false);
            },
            error: () => this.loading.set(false),
          })
        )
      )
    )
  );

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly minimumRequiredCharacters = 3;
  private readonly usersService = inject(UsersService);

  ngOnInit(): void {
    this.listenToValueChanges(this.inputFormControl.valueChanges as unknown as string);
  }

  detectChanges(): void {
    this.cdr.detectChanges();
  }

  displayFn(option: FilterDropdownOption | string): string {
    if (isString(option)) {
      return option;
    }
    return isNotNil(option) ? option.label : '';
  }

  defaultGetErrorMessage(): string {
    return 'Invalid input';
  }

  getErrorKeys(): string[] {
    return Object.keys(this.inputFormControl.errors);
  }
}
