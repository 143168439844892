import { PROTOCOL_MARK, WWW_MARK } from '@shared/constants';

export const mapToUrlWithProtocol = (url: string): `https://${string}` => {
  let combinedUrl = url;

  if (combinedUrl.startsWith(WWW_MARK)) {
    combinedUrl = PROTOCOL_MARK + url;
  }

  return combinedUrl as `https://${string}`;
};
