import { Pipe, PipeTransform } from '@angular/core';
import { RatingConstant, RatingConstants } from '@shared/constants/constants';

import { isNil } from '@shared/utils';

@Pipe({
  name: 'ratingId',
  standalone: true,
})
export class RatingIdPipe implements PipeTransform {
  transform(value?: string): RatingConstant | undefined {
    if (isNil(value)) {
      return undefined;
    }
    return RatingConstants.values.find(rating => rating.label === value)?.id;
  }
}
