import { Adapter } from '@shared/adapters/adapter';
import { getIdFromEnumItem } from '@shared/utils/other/get-id-from-enum-item';
import { RiskCaseEnumItem } from 'oneorm-api-http-client';

import { lowImpactRatingId, moderateImpactRatingId, elevatedImpactRatingId, highImpactRatingId } from '@shared/constants';

import {
  IssuesCheckboxFilter,
  IssuesImpactRatingCheckboxFilter,
  IssuesImpactRatingCheckboxFilterValue,
} from '../../home/model/issues-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

export abstract class IssueRatingEnumAdapter extends Adapter<
  Record<IssuesCheckboxFilter.impactRating, FilterCheckboxGroupModel>,
  RiskCaseEnumItem[]
> {
  static fromDto(dto: RiskCaseEnumItem[]): Record<IssuesCheckboxFilter.impactRating, FilterCheckboxGroupModel> {
    return {
      impactRating: new FilterCheckboxGroupModel<string, IssuesImpactRatingCheckboxFilter, IssuesImpactRatingCheckboxFilterValue>(
        'Impact rating',
        [
          {
            filterName: IssuesImpactRatingCheckboxFilter.low,
            label: IssuesImpactRatingCheckboxFilterValue.low,
            value: getIdFromEnumItem(dto, lowImpactRatingId),
          },
          {
            filterName: IssuesImpactRatingCheckboxFilter.moderate,
            label: IssuesImpactRatingCheckboxFilterValue.moderate,
            value: getIdFromEnumItem(dto, moderateImpactRatingId),
          },
          {
            filterName: IssuesImpactRatingCheckboxFilter.elevated,
            label: IssuesImpactRatingCheckboxFilterValue.elevated,
            value: getIdFromEnumItem(dto, elevatedImpactRatingId),
          },
          {
            filterName: IssuesImpactRatingCheckboxFilter.high,
            label: IssuesImpactRatingCheckboxFilterValue.high,
            value: getIdFromEnumItem(dto, highImpactRatingId),
          },
        ]
      ),
    };
  }
}
