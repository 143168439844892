import { PROTOCOL_MARK, WWW_MARK } from '@shared/constants';

export const isValidExternalUrl = (url: unknown, currentUrl: string): boolean => {
  if (typeof url !== 'string') {
    return false;
  }

  let combinedUrl = url;

  if (combinedUrl.startsWith(WWW_MARK)) {
    combinedUrl = PROTOCOL_MARK + url;
  }

  return Boolean(new URL(combinedUrl)) && new URL(combinedUrl).origin !== new URL(currentUrl).origin;
};
