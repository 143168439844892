import { Pipe, PipeTransform } from '@angular/core';
import { RatingConstants, RatingValue } from '@shared/constants/constants';

import { isNotNil } from '@shared/utils';

export function getRatingClass(label: string, property: keyof Pick<RatingValue, 'class' | 'chipClass'> = 'class'): string {
  let ratingValue = RatingConstants.values.find(value => value.label === label);

  return isNotNil(ratingValue) ? ratingValue[property] : '';
}

@Pipe({
  name: 'ratingClass',
  standalone: true,
})
export class RatingClassPipe implements PipeTransform {
  transform(label: string, property: keyof Pick<RatingValue, 'class' | 'chipClass'> = 'class'): string {
    return getRatingClass(label, property);
  }
}
