import { Injectable } from '@angular/core';
import { UserSettingsAdapter } from '@shared/adapters/user-settings.adapter';
import { UserSettings } from '@shared/models/user-settings.model';
import { UserSettingService as OrmUserSettingsService } from 'oneorm-api-http-client';
import { UserSettingResponse } from 'oneorm-api-http-client/model/userSettingResponse';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DefaultColumnsDefinitions, UserSettingsColumnsDef } from '../../home/constants/column-definitions';
import { userSettingsTypeToDefaultColumns } from '../../home/constants/default-columns';
import { UserSettingsEvent } from '../../home/model/user-settings-event.enum';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService<T> {
  constructor(private readonly userSettingsService: OrmUserSettingsService) {}

  getUserSettingsColumnsDefinitionsForType(
    type: UserSettingsEvent,
    columnDefinitions: DefaultColumnsDefinitions<T>
  ): Observable<UserSettingsColumnsDef<T>> {
    return this.userSettingsService.getUserSettingsByType(type).pipe(
      map(settings => settings as unknown as UserSettings<T>[]),
      map(settings =>
        UserSettingsAdapter.fromDto(settings, columnDefinitions, type, userSettingsTypeToDefaultColumns[type] as UserSettings<T>[])
      )
    );
  }

  saveUserSettingsForType(type: UserSettingsEvent, body: UserSettings<T>[]): Observable<Array<UserSettingResponse>> {
    return this.userSettingsService.saveUserSettingByType(type, body as unknown as Array<UserSettingResponse>);
  }
}
