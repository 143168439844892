import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface PopoverState {
  closedBy?: 'button' | 'outsideClick';
}

@Injectable()
export class PopoverService {
  readonly state$: Observable<PopoverState>;
  private readonly state = new BehaviorSubject<PopoverState>({});

  constructor() {
    this.state$ = this.state.asObservable();
  }

  closePopover(closedBy: PopoverState['closedBy']): void {
    return this.state.next({ closedBy });
  }

  getValue(): PopoverState {
    return this.state.getValue();
  }
}
