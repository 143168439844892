import { Directive, HostListener, Input, Optional } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl], [formControlName]',
  standalone: true,
})
export class TrimFormFieldsDirective {
  @Input() type: string;

  constructor(
    @Optional() private readonly formControlDir: FormControlDirective,
    @Optional() private readonly formControlName: FormControlName
  ) {}

  @HostListener('blur')
  @HostListener('keydown.enter')
  trimValue(): void {
    const control = this.formControlDir?.control || this.formControlName?.control;
    if (typeof control.value === 'string' && this.type !== 'password') {
      control.setValue(control.value.trim());
    }
  }
}
