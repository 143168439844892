import { inject } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { AuthorizationService } from 'oneorm-api-http-client';
import { pipe } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { TUser, UserRole } from '@shared/models';
import { GraphService } from '@shared/services';
import { isEmpty, isNil } from '@shared/utils';

const DEFAULT_USER: TUser = {
  user_id: '',
  user_status: '',
  userRole: [],
  user_name: '',
  user_email_address: '',
  userPhoto: undefined,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState<TUser>(DEFAULT_USER),
  withMethods(
    (
      store,
      authorizationService = inject(AuthorizationService),
      msalService = inject(MsalService),
      msalBroadcastService = inject(MsalBroadcastService),
      graphService = inject(GraphService)
    ) => ({
      getUserInfo: rxMethod<void>(
        pipe(
          switchMap(() => msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))),
          switchMap(() => {
            const accounts = msalService.instance.getAllAccounts();

            if (isEmpty(accounts)) {
              throw new Error('No accounts found!');
            }

            const userAccount = accounts[0];

            if (isNil(userAccount)) {
              throw new Error('No user account found');
            }

            const { onpremsam, preferred_username, name } = userAccount.idTokenClaims;
            const user: TUser = {
              user_id: onpremsam as string,
              user_email_address: preferred_username,
              user_name: name,
            };

            patchState(store, user);
            return graphService.getLoggedUserPhoto();
          }),
          tapResponse({
            next: userPhoto => patchState(store, { userPhoto }),
            error: console.error,
          })
        )
      ),
      getUserRoles: rxMethod<void>(
        pipe(
          switchMap(() =>
            authorizationService.getRolesForUser().pipe(
              map(({ data }) => data as UserRole[]),
              tapResponse({
                next: roles => patchState(store, { userRole: roles }),
                error: console.error,
              })
            )
          )
        )
      ),
    })
  ),
  withHooks({
    onInit({ getUserRoles, getUserInfo }) {
      getUserRoles();
      getUserInfo();
    },
  })
);
