import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverdueCellColorDirective } from '@styles/p-table/cell-renderers/overdue-cell-renderer/overdue-cell-color.directive';
import { InfiniteDaysPipe } from '@styles/p-table/infinite-days.pipe';
import { CellRenderer, CellRendererParams } from '@styles/p-table/model/cell-renderer.model';
import { TableColumnDef } from '@styles/p-table/model/col-def.model';

import { isNil } from '@shared/utils';

@Component({
  template: `<span [type]="'number'" [value]="fieldValue" appOverdueCellColor>{{ fieldValue | infiniteDays }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InfiniteDaysPipe, OverdueCellColorDirective],
})
export class InfiniteDaysCellRendererComponent<T> implements CellRenderer<T> {
  data: T;
  column: TableColumnDef<T>;
  fieldValue: unknown;

  cellRendererInit(params: CellRendererParams<T>): void {
    if (isNil(params.data)) {
      return;
    }
    this.fieldValue = params.data[params.column.field];
    this.data = params.data;
    this.column = params.column;
  }
}
