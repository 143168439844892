export enum UserSettingsEvent {
  MY_CASES_EVENT = 'my_cases_event',
  ALL_CASES_EVENT = 'all_cases_event',
  MY_CASES_ISSUE = 'my_cases_issue',
  ALL_CASES_ISSUE = 'all_cases_issue',
  MY_TODOS = 'my_todos',
  RCSAS = 'all_rcsas',
  MY_CONTROLS = 'my_controls',
  ALL_CONTROLS = 'all_controls',
  MY_STANDARDS = 'my_standards',
  ALL_STANDARDS = 'all_standards',
}
