import { User } from 'oneorm-api-http-client/model/user';

import { isArray } from '@shared/utils';

export const isUserArray = (row: unknown[] | unknown): row is User[] => {
  if (!isArray(row)) {
    return false;
  }
  return row.every(r => r.hasOwnProperty('user_id') && r.hasOwnProperty('user_name'));
};
