import { FilterAdvancedModel } from './filter-advanced.model';
import { FilterCheckboxDefinitionModel } from './filter-checkbox-definition.model';
import { FilterName, FilterType } from './filter.model';

export class FilterCheckboxGroupModel<
    F extends string = string,
    FILTER extends string = string,
    LABEL extends string = string,
    VALUE = string,
  >
  implements FilterName, FilterType
{
  filterName: F;
  header: string | undefined;
  checkboxFilters: FilterCheckboxDefinitionModel<FILTER, LABEL, VALUE>[];
  type: string;
  predicateModel: FilterAdvancedModel['predicatesModels'][0];
  className?: string;

  constructor(
    header: string | undefined,
    checkboxFilters: FilterCheckboxDefinitionModel<FILTER, LABEL, VALUE>[],
    predicateModel?: FilterAdvancedModel['predicatesModels'][0],
    filterName?: F,
    className?: string
  ) {
    this.header = header;
    this.checkboxFilters = checkboxFilters;
    this.predicateModel = predicateModel;
    this.filterName = filterName;
    this.className = className;
  }
}
