import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

export interface ErrorData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatButtonModule, RouterLink],
  template: `
    <mat-card class="error-card">
      <mat-card-header>
        <mat-icon color="warn">error_outline</mat-icon>
        <mat-card-title>{{ errorTitle }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>{{ errorMessage }}</p>
      </mat-card-content>
      <mat-card-actions>
        <button color="primary" mat-button routerLink="/">Go to Home</button>
      </mat-card-actions>
    </mat-card>
  `,
  styles: [
    `
      .error-card {
        max-width: 400px;
        margin: 100px auto;
        text-align: center;
      }
    `,
  ],
})
export class ErrorPageComponent implements OnInit {
  errorTitle: string = 'Error Occurred';
  errorMessage: string = 'An unexpected error occurred.';

  ngOnInit(): void {
    const errorState = history.state.error as ErrorData | undefined;

    if (errorState?.title) {
      this.errorTitle = errorState.title;
    }
    if (errorState?.message) {
      this.errorMessage = errorState.message;
    }
  }
}
