import { IssueAllOf, OpEventAllOf } from 'oneorm-api-http-client';

interface StepButton {
  label: string;
  disabled?: boolean;
  visible?: boolean;
  hoverMessage?: string;
}

interface BaseStep {
  label: string;
  route: string;
  buttons?: {
    primary?: StepButton;
    secondary?: StepButton;
    tertiary?: StepButton;
  };
  completed?: boolean;
}

export interface SubStep<
  Key extends string = string,
  Index extends string = OpEventAllOf.LastWizardIndexEnum | IssueAllOf.LastWizardIndexEnum,
> extends BaseStep {
  label: string;
  index: Index;
  stepperIndex: number;
  stepControlKey: Key;
}

export interface OrmStep<
  SubSteps extends SubStep = SubStep,
  Key extends string = string,
  Index extends string = OpEventAllOf.LastWizardIndexEnum | IssueAllOf.LastWizardIndexEnum,
> extends BaseStep {
  index?: Index;
  stepperIndex?: number;
  initialRoute?: string;
  subSteps?: SubSteps[];
  stepControlKey?: Key;
}
