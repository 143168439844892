import { Pipe, PipeTransform } from '@angular/core';
import { RATING_ID_TO_LABEL, RatingConstant } from '@shared/constants/constants';

import { isNil, ValueOf } from '@shared/utils';

@Pipe({
  name: 'ratingLabel',
  standalone: true,
})
export class RatingLabelPipe implements PipeTransform {
  transform(value?: number): ValueOf<typeof RATING_ID_TO_LABEL> | '' {
    if (isNil(value)) {
      return '';
    }
    return RATING_ID_TO_LABEL[value as RatingConstant];
  }
}
