import { Pipe, PipeTransform } from '@angular/core';
import { RcsaControlAssessor } from 'oneorm-api-http-client';

@Pipe({
  name: 'getAssessorsStatus',
  standalone: true,
})
export class GetAssessorsStatusPipe implements PipeTransform {
  transform(assessors: RcsaControlAssessor[]): string {
    return assessors
      .map(
        element =>
          `<span>${element.user_name}${
            element.user_status === 'INACTIVE' ? '<span class="inactive mat-caption"> (Inactive)</span>' : ''
          }</span>`
      )
      .join(', ');
  }
}
