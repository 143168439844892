import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';

import { DialogComponent } from '@shared/dialog';
import { IsNotNilPipe } from '@shared/pipe';

interface AlertDialogDataBase {
  title: string;
}

interface AlertDialogWithMessage extends AlertDialogDataBase {
  message: string;
  templateRef?: never;
}

interface AlertDialogWithTemplate extends AlertDialogDataBase {
  templateRef: TemplateRef<unknown>;
  message?: never;
}

export type AlertDialogData = AlertDialogWithMessage | AlertDialogWithTemplate;

@Component({
  selector: 'app-alert-dialog',
  template: `
    <app-dialog [isUserGuideVisible]="false" [loading]="false" [title]="data.title" (cancelButtonClick)="onCancelButtonClick()">
      @if (data.templateRef | isNotNil) {
        <ng-container *ngTemplateOutlet="data.templateRef"></ng-container>
      } @else {
        {{ data.message }}
      }
      <mat-dialog-actions align="end">
        <button [mat-dialog-close]="undefined" color="primary" mat-stroked-button>Cancel</button>
        <button [mat-dialog-close]="true" color="primary" mat-flat-button>Proceed</button>
      </mat-dialog-actions>
    </app-dialog>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogComponent, MatDialogActions, MatButton, MatDialogClose, IsNotNilPipe, NgTemplateOutlet],
})
export class AlertDialogComponent {
  readonly data = inject<AlertDialogData>(MAT_DIALOG_DATA);

  private readonly dialogRef = inject(MatDialogRef);

  onCancelButtonClick(): void {
    this.dialogRef.close();
  }
}
