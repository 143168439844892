export enum RiskCaseEnum {
  EVENT_STATUS = 3,
  EVENT_TYPE = 2,
  ISSUE_RATING = 5,
  ISSUE_STATUS = 6,
  LOD = 22,
  OUTSOURCING = 11,
  FUNCTIONS = 13,
  CONTROLS_RATING = 26,
}

export enum RatingConstant {
  EFFECTIVE = 232,
  NOT_EFFECTIVE = 233,
  NOT_ASSESSED = 234,
  NOT_APPLICABLE = 235,
}

export enum InfoKey {
  ISSUE_MANDATE = 'IssueMandate',
  ISSUE_EVENT = 'IssueEvent',
  ISSUE_DESCRIPTION = 'IssueDescription',
  ISSUE_IDENTIFICATION = 'IssueIdentification',
  ISSUE_RISK_CONTROL = 'IssueRiskControl',
  ISSUE_ROLES = 'IssueRoles',
  ISSUE_IMPACT_RATING = 'IssueImpactRating',
  ISSUE_REMEDIATION = 'IssueRemediation',
  ISSUE_BUSINESS_IMPACT = 'IssueBusinessImpact',
  ISSUE_LEGAL_ENTITIES = 'IssueLegalEntities',
  ISSUE_OUTSOURCING = 'IssueOutsourcing',
  ISSUE_ICOFR = 'IssueIcofr',
  ISSUE_COMPLIANCE = 'IssueCompliance',
  ISSUE_WORKFLOW_GUIDANCE = 'IssueWorkflowGuidance',
  ISSUE_ATTACHMENTS = 'IssueAttachments',
  ISSUE_SUBSCRIBED_USERS = 'IssueSubscribedUsers',
  EVENT_MANDATE = 'EventMandate',
  EVENT_ISSUE = 'EventIssue',
  EVENT_DESCRIPTION = 'EventDescription',
  EVENT_RISK = 'EventRisk',
  EVENT_ROLES = 'EventRoles',
  EVENT_BUSINESS_IMPACT = 'EventBusinessImpact',
  EVENT_LEGAL_ENTITIES = 'EventLegalEntities',
  EVENT_OUTSOURCING = 'EventOutsourcing',
  EVENT_ICOFR = 'EventIcofr',
  EVENT_MONETARY_IMPACT = 'EventMonetaryImpact',
  EVENT_BUSINESS_FUNCTION = 'EventBusinessFunction',
  EVENT_COUNTRY = 'EventCountry',
  EVENT_ROOT_CAUSE = 'EventRootCause',
  EVENT_TYPE = 'EventType',
  EVENT_COMPLIANCE = 'EventCompliance',
  EVENT_WORKFLOW_GUIDANCE = 'EventWorkflowGuidance',
  EVENT_ATTACHMENTS = 'EventAttachments',
  EVENT_SUBSCRIBED_USERS = 'EventSubscribedUsers',
}

export const tableColumnWidthValues = {
  riskCase: {
    riskName: {
      maxWidth: 160,
      minWidth: 160,
    },
    riskTitle: {
      minWidth: 300,
    },
    date: {
      maxWidth: 200,
    },
  },
  controlTab: {
    assessor: {
      minWidth: 150,
      maxWidth: 200,
    },
    rcsaNameAndOwner: {
      maxWidth: 160,
      minWidth: 140,
    },
    description: {
      maxWidth: 260,
      minWidth: 210,
    },
    rating: {
      maxWidth: 145,
    },
    key: {
      width: 75,
    },
    lastSignOff: {
      maxWidth: 200,
    },
    signOff: {
      maxWidth: 140,
      minWidth: 140,
    },
    openCases: {
      maxWidth: 160,
    },
    assessment: {
      width: 360,
    },
    evidence: {
      minWidth: 250,
    },
    assess: {
      maxWidth: 140,
      minWidth: 140,
    },
    controlNameFirstColumn: {
      maxWidth: 160,
      minWidth: 160,
    },
    control: {
      maxWidth: 125,
      minWidth: 125,
    },
    level: {
      maxWidth: 90,
      minWidth: 90,
    },
  },
};

export const RATING_LABEL = {
  [RatingConstant.EFFECTIVE]: 'Effective',
  [RatingConstant.NOT_EFFECTIVE]: 'Not Effective',
  [RatingConstant.NOT_ASSESSED]: 'Not Assessed',
  [RatingConstant.NOT_APPLICABLE]: 'Not Applicable',
};

export const RATING_ID_TO_LABEL: Record<RatingConstant, string> = {
  [RatingConstant.EFFECTIVE]: 'Effective',
  [RatingConstant.NOT_EFFECTIVE]: 'Not Effective',
  [RatingConstant.NOT_ASSESSED]: 'Not Assessed',
  [RatingConstant.NOT_APPLICABLE]: 'Not Applicable',
};

export const RATING_CLASS = {
  [RatingConstant.EFFECTIVE]: 'sr-positive-text',
  [RatingConstant.NOT_EFFECTIVE]: 'sr-negative-text',
  [RatingConstant.NOT_ASSESSED]: 'sr-highlight-text',
  [RatingConstant.NOT_APPLICABLE]: 'notApplicable',
};
export class RatingConstants {
  static readonly values = [
    {
      id: RatingConstant.EFFECTIVE,
      label: RATING_ID_TO_LABEL[RatingConstant.EFFECTIVE],
      class: 'sr-positive-text',
      chipClass: 'effective',
    },
    {
      id: RatingConstant.NOT_EFFECTIVE,
      label: RATING_ID_TO_LABEL[RatingConstant.NOT_EFFECTIVE],
      class: 'sr-negative-text',
      chipClass: 'notEffective',
    },
    {
      id: RatingConstant.NOT_ASSESSED,
      label: RATING_ID_TO_LABEL[RatingConstant.NOT_ASSESSED],
      class: 'sr-highlight-text',
      chipClass: 'notAssessed',
    },
    {
      id: RatingConstant.NOT_APPLICABLE,
      label: RATING_ID_TO_LABEL[RatingConstant.NOT_APPLICABLE],
      class: 'notApplicable',
      chipClass: 'notApplicable',
    },
  ];
}

export interface RatingValue {
  id: RatingConstant;
  label: string;
  class: string;
  chipClass: string;
}

export const INFINITE_DAYS = 9999;

export const EVENT_URL = '#/risk-case/event/';
export const ISSUE_URL = '#/risk-case/issue/';

export const selfIdentifiedLodId = 213;
export const secondLineIdentifiedLodId = 214;
export const thirdLineIdentifiedLodId = 215;
export const lowImpactRatingId = 22;
export const mediumImpactRatingId = 21;
export const moderateImpactRatingId = 20;
export const elevatedImpactRatingId = 19;
export const highImpactRatingId = 18;

export type LodId = typeof selfIdentifiedLodId | typeof secondLineIdentifiedLodId | typeof thirdLineIdentifiedLodId;