import { Injectable } from '@angular/core';
import { HomeService } from '@shared/services/home.service';
import { MyTodosService as OrmMyTodosService } from 'oneorm-api-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MyTodo, RiskCaseStatus, RiskCaseType } from '@shared/models';
import { isNil } from '@shared/utils';

import { TableData } from '../../home/model/table-data.model';

@Injectable()
export class MyTodosService extends HomeService<MyTodo> {
  constructor(private readonly oneOrmMyTodosService: OrmMyTodosService) {
    super();
  }

  getData(): Observable<TableData<MyTodo[]>> {
    return this.oneOrmMyTodosService.getMyTodos().pipe(
      map(({ data }) => {
        if (isNil(data)) {
          return { data: [] };
        }
        return {
          data: data.map(riskCase => ({
            ...riskCase,
            class_name: riskCase.class_name as RiskCaseType,
            status_name: riskCase.status_name as RiskCaseStatus,
          })) as MyTodo[],
        };
      })
    );
  }
}
