import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class ToastService {
  private readonly actionText = 'Dismiss';
  private readonly successClass = 'success-snackbar';
  private readonly errorClass = 'error-snackbar';

  constructor(private readonly snackbar: MatSnackBar) {}

  showSuccessToastFromTemplate(template: TemplateRef<unknown>, config?: MatSnackBarConfig): void {
    this.snackbar.openFromTemplate(template, { ...config, panelClass: [this.successClass] });
  }

  showSuccessToast(message: string): void {
    this.snackbar.open(message, this.actionText, {
      panelClass: [this.successClass],
    });
  }

  showErrorToast(message: string): void {
    this.snackbar.open(message, this.actionText, {
      panelClass: [this.errorClass],
    });
  }
}
