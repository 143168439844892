<div>
  <div mat-dialog-title>
    <div class="left-section">
      <h2 class="mat-headline-6" *skeleton="loading; height: skeletonHeight; width: skeletonWidth; margin: skeletonMargin">
        {{ title }}
      </h2>
      <ng-content select="[dialog-title-suffix]"></ng-content>
    </div>
    <div class="right-section">
      @if (isUserGuideVisible) {
        <app-user-guide [entityName]="entityName" [iconSize]="'small'"></app-user-guide>
      }
      @if (isCancelButtonVisible) {
        <button [tabIndex]="0" (click)="cancelButtonClick.emit()" aria-label="close-dialog" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      }
    </div>
  </div>
  <div *ngIf="dialogSubHeaderDirective | isNotNil">
    <ng-content select="dialog-sub-header"></ng-content>
  </div>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content>
  <ng-content></ng-content>
</div>
<ng-content select="mat-dialog-actions"></ng-content>
