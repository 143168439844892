import { RcsaControlAssessmentViewFE } from '@shared/services/controls.service';
import { RiskAssessment, Standard } from 'oneorm-api-http-client';

import { MyTodo, RiskCaseViewFE, UserSettings } from '@shared/models';

import { UserSettingsEvent } from '../model/user-settings-event.enum';

export const disabledColumnFields: Partial<Record<UserSettingsEvent, string[]>> = {
  [UserSettingsEvent.MY_CASES_EVENT]: ['loss', 'accounting_adjustments', 'operational_gains', 'target_due_date'],
  [UserSettingsEvent.ALL_CASES_EVENT]: ['loss', 'accounting_adjustments', 'operational_gains', 'target_due_date'],
};

export const defaultIssuesColumns: UserSettings<RiskCaseViewFE>[] = [
  { field: 'rc_source_name', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'title', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'status', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'days_until_deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'creation_date', filter: '', mode: '', isVisible: false, index: 4, selectIndex: 4 },
  { field: 'submission_date', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'target_due_date', filter: '', mode: '', isVisible: true, index: 6, selectIndex: 6 },
  { field: 'closure_date', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'cancellation_date', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8 },
  { field: 'creator', filter: '', mode: '', isVisible: false, index: 9, selectIndex: 9, subfield: 'user_name' },
  { field: 'owner', filter: '', mode: '', isVisible: true, index: 10, selectIndex: 10, subfield: 'user_name' },
  { field: 'delegate', filter: '', mode: '', isVisible: false, index: 11, selectIndex: 11, subfield: 'user_name' },
  {
    field: 'risk_manager',
    filter: '',
    mode: '',
    isVisible: false,
    index: 12,
    selectIndex: 12,
    subfield: 'user_name',
  },
  { field: 'rcsa_owner', filter: '', mode: '', isVisible: false, index: 13, selectIndex: 13, subfield: 'user_name' },
  { field: 'bu_gf', filter: '', mode: '', isVisible: false, index: 14, selectIndex: 14 },
  { field: 'identifyingLod', filter: '', mode: '', isVisible: false, index: 15, selectIndex: 15 },
  { field: 'source', filter: '', mode: '', isVisible: false, index: 16, selectIndex: 16 },
  { field: 'impactRating', filter: '', mode: '', isVisible: false, index: 17, selectIndex: 17 },
];

export const defaultEventsColumns: UserSettings<RiskCaseViewFE>[] = [
  { field: 'rc_source_name', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'title', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'status', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'days_until_deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'creation_date', filter: '', mode: '', isVisible: false, index: 4, selectIndex: 4 },
  { field: 'submission_date', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'closure_date', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
  { field: 'cancellation_date', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'creator', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8, subfield: 'user_name' },
  { field: 'owner', filter: '', mode: '', isVisible: true, index: 9, selectIndex: 9, subfield: 'user_name' },
  { field: 'delegate', filter: '', mode: '', isVisible: false, index: 10, selectIndex: 10, subfield: 'user_name' },
  {
    field: 'risk_manager',
    filter: '',
    mode: '',
    isVisible: false,
    index: 11,
    selectIndex: 11,
    subfield: 'user_name',
  },
  {
    field: 'rcsa_owner',
    filter: '',
    mode: '',
    isVisible: false,
    index: 12,
    selectIndex: 12,
    subfield: 'user_name',
  },
  { field: 'bu_gf', filter: '', mode: '', isVisible: false, index: 13, selectIndex: 13 },
];

export const defaultRcsaColumns: UserSettings<RiskAssessment>[] = [
  { field: 'name', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'description', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'sign_off_date', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'rcsa_owner', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'risk_manager', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4 },
  { field: 'global_function', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'status', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
  { field: 'type', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'folder', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8 },
];

export const defaultMyTodoColumns: UserSettings<MyTodo>[] = [
  { field: 'rc_source_name', filter: '', mode: '', isVisible: false, index: 0, selectIndex: 0 },
  { field: 'rc_title', filter: '', mode: '', isVisible: false, index: 1, selectIndex: 1 },
  { field: 'action', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2, subfield: 'user_name' },
  { field: 'deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'ownership', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4, subfield: 'user_name' },
  { field: 'class_name', filter: '', mode: '', isVisible: false, index: 5, selectIndex: 5 },
  { field: 'status_name', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
];

export const defaultControlsColumns: UserSettings<RcsaControlAssessmentViewFE>[] = [
  { field: 'ctrl_control_name', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'ctrl_description', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'ctrl_key', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'assessors', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'rating_name', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4 },
  { field: 'ca_assessment_comment', filter: '', mode: '', isVisible: false, index: 5, selectIndex: 5 },
  { field: 'ca_modified', filter: '', mode: '', isVisible: true, index: 6, selectIndex: 6 },
  { field: 'sign_off_due_date', filter: '', mode: '', isVisible: true, index: 7, selectIndex: 7 },
  { field: 'sign_off_in_period', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8 },
  { field: 'ra_name', filter: '', mode: '', isVisible: false, index: 9, selectIndex: 9 },
  { field: 'folder', filter: '', mode: '', isVisible: false, index: 10, selectIndex: 10 },
  { field: 'ra_global_function', filter: '', mode: '', isVisible: true, index: 11, selectIndex: 11 },
  { field: 'ctrl_open_cases', filter: '', mode: '', isVisible: false, index: 12, selectIndex: 12 },
];

export const defaultStandardsColumns: UserSettings<Standard>[] = [
  { field: 'doc_reference_id', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'name', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'description', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'owner', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'topic', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4 },
  { field: 'status', filter: '', mode: '', isVisible: false, index: 5, selectIndex: 5 },
  { field: 'modified', filter: '', mode: '', isVisible: true, index: 6, selectIndex: 6 },
  { field: 'source_url_name', filter: '', mode: '', isVisible: true, index: 7, selectIndex: 7 },
];

type DefaultColumnType =
  | typeof defaultRcsaColumns
  | typeof defaultIssuesColumns
  | typeof defaultEventsColumns
  | typeof defaultMyTodoColumns
  | typeof defaultControlsColumns
  | typeof defaultStandardsColumns;

export const userSettingsTypeToDefaultColumns: Record<UserSettingsEvent, DefaultColumnType> = {
  [UserSettingsEvent.ALL_CASES_EVENT]: defaultEventsColumns,
  [UserSettingsEvent.MY_CASES_EVENT]: defaultEventsColumns,
  [UserSettingsEvent.ALL_CASES_ISSUE]: defaultIssuesColumns,
  [UserSettingsEvent.MY_CASES_ISSUE]: defaultIssuesColumns,
  [UserSettingsEvent.MY_TODOS]: defaultMyTodoColumns,
  [UserSettingsEvent.RCSAS]: defaultRcsaColumns,
  [UserSettingsEvent.MY_CONTROLS]: defaultControlsColumns,
  [UserSettingsEvent.ALL_CONTROLS]: defaultControlsColumns,
  [UserSettingsEvent.MY_STANDARDS]: defaultStandardsColumns,
  [UserSettingsEvent.ALL_STANDARDS]: defaultStandardsColumns,
};
