import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DialogSubHeaderDirective } from '@shared/dialog/dialog-sub-header.directive';
import { IsNotNilPipe } from '@shared/pipe/is-not-nil.pipe';

import { UserGuideComponent } from '@shared/components';
import { SkeletonLoaderDirective } from '@shared/directives';
import { EntityName } from '@shared/models';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    UserGuideComponent,
    MatIconModule,
    MatButtonModule,
    DialogSubHeaderDirective,
    NgIf,
    IsNotNilPipe,
    MatDividerModule,
    SkeletonLoaderDirective,
  ],
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class DialogComponent {
  @Input() loading = true;
  @Input() title: string;
  @Input() entityName: EntityName;
  @Input() isUserGuideVisible = true;
  @Input() isCancelButtonVisible = true;
  @Output() cancelButtonClick = new EventEmitter<void>();
  @ContentChild(DialogSubHeaderDirective) dialogSubHeaderDirective: DialogSubHeaderDirective;

  readonly skeletonHeight = '24px';
  readonly skeletonWidth = '400px';
  readonly skeletonMargin = '0 0 8px 0';
}
