import { Pipe, PipeTransform } from '@angular/core';
import { INFINITE_DAYS } from '@shared/constants/constants';

import { isNil } from '@shared/utils';

@Pipe({
  name: 'infiniteDays',
  standalone: true,
})
export class InfiniteDaysPipe implements PipeTransform {
  private readonly infiniteDaysMarker = '';

  transform(value: unknown): string {
    if (isNil(value)) {
      return '';
    }

    if (value === INFINITE_DAYS) {
      return this.infiniteDaysMarker;
    }

    return value.toString();
  }
}
