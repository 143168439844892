import { Adapter } from '@shared/adapters/adapter';

import { RiskCaseViewFE, UserSettings } from '@shared/models';
import { invert, isEmpty, isNil, isNotEmpty, isNotNil } from '@shared/utils';

import { DefaultColumnDefinition, DefaultColumnsDefinitions, UserSettingsColumnsDef } from '../../home/constants/column-definitions';
import { disabledColumnFields, userSettingsTypeToDefaultColumns } from '../../home/constants/default-columns';
import { UserSettingsEvent } from '../../home/model/user-settings-event.enum';

// TODO: Remove once BE is adopted to 'USER' objects
const NAME_TO_USER_OBJECT: Partial<Record<keyof RiskCaseViewFE, keyof RiskCaseViewFE>> = {
  creator_name: 'creator',
  delegate_name: 'delegate',
  owner_name: 'owner',
  rcsa_owner_name: 'rcsa_owner',
  risk_manager_name: 'risk_manager',
  governance_coordinator: 'governanceCoordinator',
};

const USER_OBJECT_TO_NAME: Partial<Record<keyof RiskCaseViewFE, keyof RiskCaseViewFE>> = invert(NAME_TO_USER_OBJECT);

export abstract class UserSettingsAdapter<T> extends Adapter<UserSettingsColumnsDef<T>, UserSettings[]> {
  static fromDto<T>(
    dto: UserSettings[],
    columnDefinitions: DefaultColumnsDefinitions<T>,
    type: UserSettingsEvent
  ): UserSettingsColumnsDef<T> {
    if (isNil(dto)) {
      return [];
    }

    if (isEmpty(dto)) {
      dto.push(...(userSettingsTypeToDefaultColumns[type] as UserSettings[]));
    }

    return dto
      .filter(userSettings => isNotEmpty(userSettings.header))
      .filter(userSettings => userSettings.field !== 'columnAction')
      .filter(userSettings => !disabledColumnFields[type]?.includes(userSettings.field))
      .map(UserSettingsAdapter.mapRiskCaseUserSettingToUserTypeField)
      .map(userSettings => {
        if (isNil(columnDefinitions)) {
          console.warn('There are no column definitions provided.');
          return userSettings;
        }

        const column: DefaultColumnDefinition<T> = columnDefinitions[userSettings.field as keyof T as Extract<keyof T, string>];

        if (isNil(column)) {
          console.warn(`There is missing column definition for ${userSettings.field}`);
        }

        return {
          ...userSettings,
          ...(isNotNil(column) && { ...column }),
        };
      }) as unknown as UserSettingsColumnsDef<T>;
  }

  static toDto<T>(columns: UserSettingsColumnsDef<T>): UserSettings[] | undefined {
    if (isNil(columns)) {
      return undefined;
    }

    return columns
      .filter(col => col.field !== 'columnAction')
      .map(
        col =>
          ({
            field: col.field,
            header: col.header,
            filter: col.filter,
            mode: col.mode,
            isVisible: col.isVisible,
            index: col.index,
            selectIndex: col.selectIndex,
            ...(isNotNil(col.subfield) && { subfield: col.subfield }),
          }) as UserSettings
      )
      .map(UserSettingsAdapter.mapRiskCaseUserSettingsToOldField);
  }

  private static mapRiskCaseUserSettingToUserTypeField(userSettings: UserSettings): UserSettings {
    if (isNil(NAME_TO_USER_OBJECT[userSettings.field as keyof RiskCaseViewFE])) {
      return userSettings;
    }
    return {
      ...userSettings,
      field: NAME_TO_USER_OBJECT[userSettings.field as keyof RiskCaseViewFE],
    };
  }

  private static mapRiskCaseUserSettingsToOldField(userSettings: UserSettings): UserSettings {
    if (isNil(USER_OBJECT_TO_NAME[userSettings.field as keyof RiskCaseViewFE])) {
      return userSettings;
    }
    return {
      ...userSettings,
      field: USER_OBJECT_TO_NAME[userSettings.field as keyof RiskCaseViewFE],
    };
  }
}
