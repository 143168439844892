import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isNil, isString } from '@shared/utils';

const textEncoder = new TextEncoder();

export function maxByteLengthValidator(maxBytes: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isNil(control.value) || !isString(control.value)) {
      return null;
    }

    const byteLength = textEncoder.encode(control.value).length;

    return byteLength > maxBytes ? { maxByteLength: { requiredBytes: maxBytes, actualBytes: byteLength } } : null;
  };
}
