import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { RatingClassPipe } from '@shared/pipe/rating-class.pipe';
import { CellRenderer, CellRendererParams } from '@styles/p-table/model/cell-renderer.model';

import { isTrue } from '@shared/utils';

@Component({
  template: ` <span>{{ label() }}</span> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RatingClassPipe],
})
export class BooleanCellRendererComponent<T extends object> implements CellRenderer<T> {
  label = signal('');

  cellRendererInit(params: CellRendererParams<T>): void {
    const value = params.data[params.column.field] as boolean;
    this.label.set(isTrue(value) ? 'Yes' : 'No');
  }
}
