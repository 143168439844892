import { RiskAssessment } from 'oneorm-api-http-client';
import { RcsaControlAssessmentView } from 'oneorm-api-http-client/model/rcsaControlAssessmentView';

import { MyTodo, RiskCaseViewFE } from '@shared/models';

import { UserSettingsColumnsDef } from './column-definitions';
import { UserSettingsEvent } from '../model/user-settings-event.enum';

export const disabledColumnFields: Partial<Record<UserSettingsEvent, string[]>> = {
  [UserSettingsEvent.MY_CASES_EVENT]: ['loss', 'accounting_adjustments', 'operational_gains', 'target_due_date'],
  [UserSettingsEvent.ALL_CASES_EVENT]: ['loss', 'accounting_adjustments', 'operational_gains', 'target_due_date'],
};

export const defaultIssuesColumns: UserSettingsColumnsDef<RiskCaseViewFE> = [
  { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'title', header: 'Title', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'status', header: 'Status', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'days_until_deadline', header: 'Days to Next Deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'creation_date', header: 'Creation Date', filter: '', mode: '', isVisible: false, index: 4, selectIndex: 4 },
  { field: 'submission_date', header: 'Submission Date', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'target_due_date', header: 'Target Due Date', filter: '', mode: '', isVisible: true, index: 6, selectIndex: 6 },
  { field: 'closure_date', header: 'Completion Date', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'cancellation_date', header: 'Cancellation Date', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8 },
  { field: 'creator', header: 'Creator', filter: '', mode: '', isVisible: false, index: 9, selectIndex: 9, subfield: 'user_name' },
  { field: 'owner', header: 'Owner', filter: '', mode: '', isVisible: true, index: 10, selectIndex: 10, subfield: 'user_name' },
  { field: 'delegate', header: 'Delegate', filter: '', mode: '', isVisible: false, index: 11, selectIndex: 11, subfield: 'user_name' },
  {
    field: 'risk_manager',
    header: 'Risk Manager',
    filter: '',
    mode: '',
    isVisible: false,
    index: 12,
    selectIndex: 12,
    subfield: 'user_name',
  },
  { field: 'rcsa_owner', header: 'RCSA Owner', filter: '', mode: '', isVisible: false, index: 13, selectIndex: 13, subfield: 'user_name' },
  { field: 'bu_gf', header: 'BU/GF', filter: '', mode: '', isVisible: false, index: 14, selectIndex: 14 },
  { field: 'identifyingLod', header: 'LoD', filter: '', mode: '', isVisible: false, index: 15, selectIndex: 15 },
  { field: 'source', header: 'Source', filter: '', mode: '', isVisible: false, index: 16, selectIndex: 16 },
  { field: 'impactRating', header: 'Impact rating', filter: '', mode: '', isVisible: false, index: 17, selectIndex: 17 },
];

export const defaultEventsColumns: UserSettingsColumnsDef<RiskCaseViewFE> = [
  { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'title', header: 'Title', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'status', header: 'Status', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'days_until_deadline', header: 'Days to Next Deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'creation_date', header: 'Creation Date', filter: '', mode: '', isVisible: false, index: 4, selectIndex: 4 },
  { field: 'submission_date', header: 'Submission Date', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'closure_date', header: 'Closure Date', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
  { field: 'cancellation_date', header: 'Cancellation Date', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'creator', header: 'Creator', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8, subfield: 'user_name' },
  { field: 'owner', header: 'Owner', filter: '', mode: '', isVisible: true, index: 9, selectIndex: 9, subfield: 'user_name' },
  { field: 'delegate', header: 'Delegate', filter: '', mode: '', isVisible: false, index: 10, selectIndex: 10, subfield: 'user_name' },
  {
    field: 'risk_manager',
    header: 'Risk Manager',
    filter: '',
    mode: '',
    isVisible: false,
    index: 11,
    selectIndex: 11,
    subfield: 'user_name',
  },
  {
    field: 'rcsa_owner',
    header: 'RCSA Owner',
    filter: '',
    mode: '',
    isVisible: false,
    index: 12,
    selectIndex: 12,
    subfield: 'user_name',
  },
  { field: 'bu_gf', header: 'BU/GF', filter: '', mode: '', isVisible: false, index: 13, selectIndex: 13 },
];

export const defaultRcsaColumns: UserSettingsColumnsDef<RiskAssessment> = [
  { field: 'name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'description', header: 'Title', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'sign_off_date', header: 'Last Sign-off Date', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'rcsa_owner', header: 'Owner', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'risk_manager', header: 'Risk Manager', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4 },
  { field: 'global_function', header: 'BU/GF', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'status', header: 'Status', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
  { field: 'type', header: 'Type', filter: '', mode: '', isVisible: false, index: 7, selectIndex: 7 },
  { field: 'folder', header: 'Folder', filter: '', mode: '', isVisible: false, index: 8, selectIndex: 8 },
];

export const defaultMyTodoColumns: UserSettingsColumnsDef<MyTodo> = [
  { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: false, index: 0, selectIndex: 0 },
  { field: 'rc_title', header: 'Title', filter: '', mode: '', isVisible: false, index: 1, selectIndex: 1 },
  { field: 'action', header: 'Action', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2, subfield: 'user_name' },
  { field: 'deadline', header: 'Deadline', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'ownership', header: 'Role', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4, subfield: 'user_name' },
  { field: 'class_name', header: 'Case Type', filter: '', mode: '', isVisible: false, index: 5, selectIndex: 5 },
  { field: 'status_name', header: 'Status', filter: '', mode: '', isVisible: false, index: 6, selectIndex: 6 },
];

export const defaultControlsColumns: UserSettingsColumnsDef<RcsaControlAssessmentView> = [
  { field: 'ctrl_control_name', header: 'Control ID', filter: '', mode: '', isVisible: true, index: 0, selectIndex: 0 },
  { field: 'ctrl_description', header: 'Description', filter: '', mode: '', isVisible: true, index: 1, selectIndex: 1 },
  { field: 'ctrl_key', header: 'Key', filter: '', mode: '', isVisible: true, index: 2, selectIndex: 2 },
  { field: 'assessors', header: 'Assessor', filter: '', mode: '', isVisible: true, index: 3, selectIndex: 3 },
  { field: 'rating_name', header: 'Rating', filter: '', mode: '', isVisible: true, index: 4, selectIndex: 4 },
  { field: 'ca_assessment_comment', header: 'Assessment commentary', filter: '', mode: '', isVisible: true, index: 5, selectIndex: 5 },
  { field: 'ca_modified', header: 'Last Sign Off', filter: '', mode: '', isVisible: true, index: 6, selectIndex: 6 },
  { field: 'sign_off_in_period', header: 'Signed off in period?', filter: '', mode: '', isVisible: true, index: 7, selectIndex: 7 },
  { field: 'ctrl_open_cases', header: 'Open Case', filter: '', mode: '', isVisible: true, index: 8, selectIndex: 8 },
];

type DefaultColumnType =
  | typeof defaultRcsaColumns
  | typeof defaultIssuesColumns
  | typeof defaultEventsColumns
  | typeof defaultMyTodoColumns
  | typeof defaultControlsColumns;

export const userSettingsTypeToDefaultColumns: Record<UserSettingsEvent, DefaultColumnType> = {
  [UserSettingsEvent.ALL_CASES_EVENT]: defaultEventsColumns,
  [UserSettingsEvent.MY_CASES_EVENT]: defaultEventsColumns,
  [UserSettingsEvent.ALL_CASES_ISSUE]: defaultIssuesColumns,
  [UserSettingsEvent.MY_CASES_ISSUE]: defaultIssuesColumns,
  [UserSettingsEvent.MY_TODOS]: defaultMyTodoColumns,
  [UserSettingsEvent.RCSAS]: defaultRcsaColumns,
  [UserSettingsEvent.MY_CONTROLS]: defaultControlsColumns,
  [UserSettingsEvent.ALL_CONTROLS]: defaultControlsColumns,
};
