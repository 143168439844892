import { Pipe, PipeTransform } from '@angular/core';

import { isNil } from '@shared/utils';

@Pipe({
  name: 'toUTF8Count',
  standalone: true,
})
export class Utf8CountPipe implements PipeTransform {
  private readonly textEncoder = new TextEncoder();

  transform(value?: string): number {
    if (isNil(value)) {
      return 0;
    }
    return this.textEncoder.encode(value).length;
  }
}
